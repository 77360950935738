var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "container d-flex rules" },
      [
        _c("base-page", {
          attrs: {
            page: _vm.rulesPage,
            contentModalClass: "rule-modal",
            contentClass: "rule-content",
          },
          on: { selectedIndex: _vm.onContentSelect },
          scopedSlots: _vm._u([
            {
              key: "page-title",
              fn: function () {
                return [
                  _c("h1", {
                    staticClass: "w-100 text-center",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("pages.rules.title")),
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "selected-content",
              fn: function () {
                return [
                  _c("div", { staticClass: "list-item-heading" }, [
                    _c(
                      "ul",
                      _vm._l(
                        _vm.rulesPage[_vm.selectedRuleIndex].rules,
                        function (rule, index) {
                          return _c("li", { key: `rule-${index}` }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.formatContent(rule)) +
                                "\n            "
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }