<template>
  <div class="main">
    <div class="container d-flex rules">
      <base-page
        :page="rulesPage"
        @selectedIndex="onContentSelect"
        contentModalClass="rule-modal"
        contentClass="rule-content"
      >
        <template #page-title>
          <h1 class="w-100 text-center" v-html="$t('pages.rules.title')"></h1>
        </template>
        <template #selected-content>
          <div class="list-item-heading">
            <ul>
              <li
                v-for="(rule, index) in rulesPage[selectedRuleIndex].rules"
                :key="`rule-${index}`"
              >
                {{ formatContent(rule) }}
              </li>
            </ul>
          </div>
        </template>
      </base-page>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasePage from '@/components/Pages/BasePage.vue'
import { rulesPage } from '@/data/pageContents'
import { formatBasePageContent } from '@/constants/config'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      rulesPage,
      selectedRuleIndex: 0
    }
  },
  methods: {
    onContentSelect(index) {
      let self = this
      self.selectedRuleIndex = index
    },
    formatContent(content) {
      return formatBasePageContent(content)
    }
  },
  computed: {
    ...mapGetters(['locale', 'currency'])
  }
}
</script>
<style lang="scss" scoped>
.rule-content {
  .list-item-heading {
    font-size: 0.9rem;
  }
}
</style>
